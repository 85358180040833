@import "../../style/mixin.scss";

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.sideMenuWrap {
  position: fixed;
  &.notReady{
    display: none;
  }
  &.open {
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  &.close {
    z-index: 99;
    height: 950%;
    width: 100vw;
    background-color: #000000;
    animation-name: fadeout;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  .closeIcon{
    text-align: right;
    padding-top: 30px;
    padding-right: 33px;
  }

  .ideMenuItems{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 41px 24px 0px 24px;
    
    .menuWrap{
      width: 100%;
      max-width: 650px;
      background-color: #414263;
      @include font("mdm");
      color: #ffffff;
      height: 60px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .menuName{
        padding-left: 19px;
      }
    }
  }
}