@import "../../../../../style/mixin";

.finger{
  position: absolute;
  top: 150px;
  right: 110px;
}

.profileDropDown {
  position: fixed;
  width: 272px;
  height: 250px;

  background-color: #141414;
  border-radius: 8px;
  right: 30px;
  top: 100px;
  z-index: 2;

  .profileDropDownWrap {
    display: flex;
    flex-direction: column;
    padding: 18px 16px 18px 16px;
    gap: 12px;
  }

  .dropDownProfileWrap {
    padding: 0px 16px 18px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    .propfileInfo {
      display: flex;
      flex-direction: column;

      .profileInfoName {
        color: var(--base-900-dark, #fff);
        font-size: 16px;
        font-family: Pretendard;
        font-weight: 500;
        line-height: 24px;
      }
      .profileInfoEmail {
        color: var(--base-500-dark-secondary, rgba(255, 255, 255, 0.5));
        font-size: 14px;
        font-family: Pretendard;
        line-height: 20px;
      }
    }
  }
  .profileDropDownContainer2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;
    gap: 12px;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 4px #434343, 0px 0px 4px 8px #3b82f6;

    .profileDropDownText {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #ffffff;
      @include font("smb");
      align-items: center;
      cursor: pointer;
    }
    .profileDropDownIcon{
      display: flex;
      align-items: center;
    }
    .profileDropDownText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #ffffff;
      @include font("smb");
    }
  }

  .profileDropDownContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;
    gap: 12px;
    cursor: pointer;
    .profileDropDownIcon{
      display: flex;
      align-items: center;
    }

    .profileDropDownText {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #ffffff;
      @include font("smb");
      align-items: center;
    }
    .profileDropDownText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #ffffff;
      @include font("smb");
    }
  }
  .profileDropDownWrap :hover {
    background-color: rgb(24, 24, 24);
  }

  .profileDropDownWrap {
    display: flex;
    flex-direction: column;

    .profileDropDownText {
      display: flex;
      flex-direction: row;
    }
  }
}

.profileDropDownClose {
  display: none;
}
