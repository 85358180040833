

.Button{ 
    color: #ffffff;
    font-family: "poppinsMedium";
    font-size: 15px;
    line-height: 22.5px;
    font-weight: 600;
    text-align: center;
    background-color:#F97447;

    border-radius: 5px;
    border: 1px solid #F97447;

    cursor: pointer;


    &.Borderless {
		border-width: 0;
	}

    &.Btn-opac{
        background-color: inherit;
    }

    &.Btn-org {
        background-color: 
        #F97447;
    }
}