.footerWrap{
  width: 100%;
  height: 430px;
  background-color: #030523;
//   @media screen and (max-width: "1260px") {
//       padding-left: 50px;
//       padding-right: 50px;
//   }

  .logo{
      width: 100%;
      max-width: 1260px;
      display: flex;
      justify-content: flex-start;
      margin: 0 auto;
      padding-top: 50px;
      @media screen and (max-width: "1260px") {
        padding-left: 50px;
        padding-right: 50px;
    }
  }

  .footerContainer{
      width: 100%;
      max-width: 1260px;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      padding-bottom: 53px;
      gap: 120px;
      @media screen and (max-width: "730px") {
          gap: 50px;
      }
        @media screen and (max-width: "1260px") {
      padding-left: 50px;
      padding-right: 50px;
  }
      

      .companyContect{
          display: flex;
          flex-direction: column;

          .companyContectText{
              color: #F97447;
              font-size: 12px;
              font-family: "poppinsLight";
              line-height: 18px;
              display: flex;
              flex-direction: column;
              gap: 38px;
          }

          .companyIcon{
              display: flex;
              flex-direction: row;
              gap: 8px;
              padding-top: 55px;

              .circle{
                  width: 29px;
                  height: 29px;
                  display: flex;

                  cursor: pointer;

                  justify-content: center;
                  align-items: center;

                  border-radius: 100%;
                  background-color: rgb(255, 255, 255, 0.4);
              }
          }
      }

      .companyInfo {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 140px;
          @media screen and (max-width: "900px") {
              gap: 50px;
          }
          
          .infoList{
              white-space: nowrap;
              display: flex;
              gap: 12px;
              flex-direction: column;
              :nth-child(1) {
                  font-family: "poppinsLight";
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 27px;
                  color: #FFFFFF;
              }
              font-family: "poppinsLight";
              font-weight: 600;
              font-size: 12px;
              line-height: 27px;
              color: rgba(255, 255, 255, 0.6);
          }
      }
  }
  .line {
      box-shadow: 0 0 0 0.2px rgba(255, 255, 255, 0.7);
      -webkit-box-shadow: 0 0 0 0.2px rgba(255, 255, 255, 0.7); /* Safari 3-4 */
      -moz-box-shadow: 0 0 0 0.2px rgba(255, 255, 255, 0.7); /* Firefox 3.5 - 3.6 */
      -ms-box-shadow: 0 0 0 0.2px rgba(255, 255, 255, 0.7); /* IE9 */
      box-shadow: 0 0 0 0.2px rgba(255, 255, 255);
      filter: blur(1px);
    }
    .author{
      width: 100%;
      max-width: 1260px;
      margin: 0 auto;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      font-size: 12px;
      line-height: 70px;
      font-family: "poppinsLight";
      justify-content: flex-start;
      .authorChild2{
          margin-left: auto;
      }
  }
}

