
.menuOverWrap{
  width: 180px;
  height: 48px;
  box-shadow: 0px 0px 0px 3px #2E88F6, 0px 0px 10px 6px #8BB7FF;
  position: absolute;
  left: -210px;
  top: -65px;
  border-radius: 8px;
  cursor: pointer;
}

.finger{
  position: absolute;
  top: -90px;
  transform: rotate(-90deg);
}

.revenueArticle{
  width:100%;
  max-width: 1260px;
  height: 105vh;
  position: relative;


  .revenueWrap{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .revenueTitle{
      color: var(--base-00-dark-primary, #141414);

      margin-bottom: 50px;
      font-size: 30px;
      font-family: Pretendard;
      font-weight: 700;
      line-height: 42px;
    }
      padding: 60px;
  }
}