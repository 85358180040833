@import "../../style//mixin.scss";

.progressBoxWrap{
    width: 100%;
    max-width: 304px;
    height: auto;

    border-radius: 20px;
    background-color: #f5f5f5;

    @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: 240px;
    }

    .progressBoxHeader{
        padding-left: 25px;
        padding-top: 19px;
        font-size: 22px;
        font-weight: 600;
        @include font("smb");

        @media screen and (max-width: 1100px) {
            font-size: 18px;
            padding-top: 10px;
        }
        
    }

    .progressBoxList {
        font-size: 15px;
        font-weight: 500;
        @include font("mdm");
        padding-bottom: 10px;
        @media screen and (max-width: 1100px) {
            font-size: 12px;
            padding-right: 8px;
            
        }
    }

    .progressBoxListKo {
        font-size: 15px;
        font-weight: 500;
        @include font("koNotoM");
        padding-bottom: 10px;
        @media screen and (max-width: 1100px) {
            font-size: 12px;
            padding-right: 8px;

        }
    }
}