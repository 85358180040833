@import "../../../../style/mixin.scss";

.joinWrap {
  width: 100%;
  height: 456px;

  background: #f5f5f5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 35px;

  @media screen and (max-width: 730px) and (min-width: 645px) {
    height: 350px;
  }

  .joinHeader {
    @include large-text;
    @include orange;
  }
  
  .descWrap {
    line-height: 32px;
    @include font("mdm");
    font-size: 12px;

    .desc1{
      font-size: 18px;
      @include  font("mdm");
    }
    .desc2{
      font-size: 18px;
      @include  font("mdm");
    }

    .desc1Ko{
      font-size: 18px;
      @include  font("koNotoM");
    }
    .desc2Ko{
      font-size: 18px;
        @include  font("koNotoM");
    }
  }

  @media screen and (max-width: 750px) {
    padding: 0px 40px 0px 40px;
    text-align: center;
  }
  .buttonWrap {
    margin-top: 30px;
  }
}
