
.finger{
  position: absolute;
  top: 200px;
  transform: rotate(-90deg);
  left: 280px;
}

.menuCover{
  width: 232px;
  height: 48px;
  box-shadow: 0 0 0 3px #2E88F6, 0 0 10px 6px #8BB7FF;
  position: absolute;
  left: 8px;
  top: 227px;
  border-radius: 8px;
  cursor: pointer;
}

.projectDetailArticle{
  width: 100%;
  background-color: #ffffff;
  .projectDetailBanner{
    background-image: url("../../../../public/image/jisooCover.png");
    height: 250px;
    background-size: cover;
    background-position: center;
  }

    .dealContainer{
      display: flex;
      flex-direction: row;
      gap: 33px;
      width: 100%;
      max-width: 960px;
      .graph{
        width: 100%;
        max-width: 560px;
      }
      .info {
        width: 100%;
        max-width: 400px;
      }
      @media screen and (max-width: 1550px){
        .graph{
          width: 478px;
        }
        .info {
          width: 373px;
        }
      }
    }
  .chainInfo{
    width: 100%;
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);
    .chainInfoWrap{
      padding: 21px 0 21px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 51px;

      .chainItemWrap{
        display: flex;
        flex-direction: column;
        .chainInfoTitle{color: #8C8C8C;
          font-size: 16px;
          font-family: Pretendard;
          font-weight: 500;
          line-height: 24px;}
      }
      .chainInfovalue2{
        color: #111;
        font-size: 20px;
        font-family: Pretendard;
        font-weight: 700;
        line-height: 32px;
        display:flex;
        flex-direction: row;
        align-items: center;
      }
      .chainInfovalue{
        color: #111;
        font-size: 20px;
        font-family: Pretendard;
        font-weight: 700;
        line-height: 32px;
        align-items: center;
      }
    }
  }

  .projectSchedule{
    width: 100%;
    border-top: 1px solid rgba(26, 26, 26, 0.1);
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);

    .projectScheduleWrap{
      padding: 21px 0 21px 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .projectScheduleTitle{
        display: flex;
        flex-direction: row;
        gap: 24px;
        color: #000;
        font-size: 20px;
        font-family: Pretendard;
        font-weight: 600;
        line-height: 32px;

      }

      .projectScheduleDetail{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .scheduleWrap{
          display: flex;
          flex-direction: row;
          align-items: center;
          .scheduleTitle{
            width: 134px;
            color: var(--base-500-light-secondary, rgba(26, 26, 26, 0.50));

            font-size: 16px;
            font-family: Pretendard;
            font-weight: 500;
            line-height: 24px;
          }

          .scheduleInfoWrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:12px;
            color: var(--base-900-light, #1A1A1A);
            font-size: 18px;
            font-family: Pretendard;
            font-weight: 600;
            line-height: 30px;
          }
        }
      }
    }
  }

  .projectDetailContents{
    width:100%;
    max-width: 1440px;
    margin: 0 auto ;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 1550px){
      padding: 0 30px 0 30px;
      max-width: 900px;
    }

    .contentHeaderWrap{

      .contentInfo{
        padding-top: 90px;

          display:flex;
          flex-direction: column;
          gap: 28px;
        .contentTitle{
          color: #111;
          font-size: 30px;
          font-family: Pretendard;
          font-weight: 700;
          line-height: 42px;

        }
        .contentDetailDesc{
          color: var(--base-00-dark-primary, #141414);
          font-size: 16px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 24px;
          width: 100%;
          max-width: 1260px;
        }
        .contentDetail{
          display:flex;
          flex-direction: row;
          gap: 14px;
          height: 24px;
          color: var(--base-00-dark-primary, #141414);
          font-size: 16px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 24px;

          .contentDetailTitle{
            border-right: 1px solid rgba(26, 26, 26, 0.1);
            width: 108px;
          }
          .contentDetailContent{
            border-right: 1px solid rgba(26, 26, 26, 0.1);
            width: 140px;
            display: flex;
            flex-direction: row;
            gap: 14px;
            align-items: center;
          }
          .contentDetailRoyalty{
            display: flex;
            flex-direction: row;
            gap: 14px;
            align-items: center;
          }
        }
      }


      .projectImage{
        width: 108px;
        height: 108px;
        border: 4px solid #ffffff;
        border-radius: 20px;
        position: absolute;
        top: 270px;
      }
      .projectImage img {
        border-radius: 20px;
      }
    }
  }
}