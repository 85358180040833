@import "../../../style/mixin.scss";

.mobileProcessSection{
  height: 1211px;
  .articleDesc{
    padding-top: 78px;
    height: 288px;
    background-color: #030523;
    text-align: center;
    @media screen and (min-width: "650px") {
      height: 350px;
    }

    .descWrap{
      display: flex;
      flex-direction: column;
      gap: 20px;
      h1{
        @include orange;
        @include font("smb");
        font-size: 14px;
        line-height: 21px;
      }
      h2{
        color: #ffffff;
        font-size: 28px;
        @include font("smb");
        line-height: 36px;
      }
      .desc3 {
        @include orange;
        @include font("smb");
        font-size: 12px;
        line-height: 18px;
      }
      .desc3Ko{
        @include orange;
        @include font("koNotoM");
        font-size: 12px;
        line-height: 18px;
      }

      @media screen and (min-width: "650px") {
        h1{
          @include orange;
          @include font("smb");
          font-size: 24px;
          line-height: 21px;
        }
        h2{
          color: #ffffff;
          font-size: 42px;
          @include font("smb");
          line-height: 36px;
        }
        :nth-child(3) {
          @include orange;
          @include font("smb");
          font-size: 18px;
          line-height: 25px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
  .articlePhase{
    height: 823px;
    display: flex;
    flex-direction: column;
    padding: 54px 32px 44px 25px;
    gap: 39px;
    .phaseWrap{
      display: flex;
      flex-direction: row;
      gap: 7px;
      justify-content: center;
      .progressBoxGroup1{
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    
    .stepWrap{
      display: flex;
      flex-direction: column;
      gap: 100px;
      :nth-child(1) {
        margin-bottom: 100px;
      }
      :nth-child(3){
        margin-top: 20px;
      }
    }
    .progressBoxWrap{
      display: flex;
      flex-direction: column;
      gap: 40px;

    }
  }
}