.companyInfoCards {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
  bottom: 180px;
  display: flex;
  gap: 56px;
  flex-direction: row;
  justify-content: center;
}