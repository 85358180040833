@import "../../../style/mixin";

.projectRegArticle{
  background-color: #FFFFFF;
  border-top: 1px solid rgba(26,26,26, 0.1);
  width: 100%;
  max-width: 1440px;
  margin: 32px auto;
  border-radius: 8px;
  position: relative;
  .finger{
    position: absolute;
    bottom: 180px;
    right: 200px;
    transform: rotate(180deg);
  }

  .projectRegWrap{
    padding: 110px 112px 120px 112px;
    display: flex;
    flex-direction: row;
    gap: 64px;
    .projectRegMainContent img {
      width: 900px;
      height: 1519px;
      @media screen and (max-width: 1550px) {
        width: 800px;
        height: 1100px;
      }
    }

    .projectRegMainContent{
      padding-top: 55px;

      .projectRegInputBtnWrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap:20px;

        .projectRegInputBtnCancel{
          width: 225px;
          height: 58px;
          background-color: #F4F4F5;
          border-radius: 8px;
          @include font("preSB");
          border: none;
        }
        .projectRegInputBtnSave{
          width: 225px;
          height: 58px;
          background-color: #1D4FD7;
          border-radius: 8px;
          box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8BB7FF;
          cursor: pointer;
          border: none;
          color: #ffffff;
          @include font("preSB");
        }
      }
    }

    .projectRegSideContent{
      display: flex;
      flex-direction: column;
      gap: 74px;

      .projectRegSideHeader{
        font-size: 30px;
        @include font("preBold");
        color: #000000;
      }

      .projectRegSideContentImageWrap{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .projectRegSideContentImageDesc{
          @include font("preSB");
          font-size: 16px;
          color: rgba(26,26,26, 0.7);
        }

        .projectRegSideContentImage{
        max-width: 320px; /* 원하는 최대 넓이 */
        max-height: 320px; /* 원하는 최대 높이 */
        display: flex;
        justify-content: center;
        align-items: center;
        }

        .projectRegSideContentImage img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}