@import "../../../../../style/mixin.scss";

.incWrap {
    width: 100%;
    height: 856px;
    display: flex;
    justify-content: center;

    .incContainer {
        padding-top: 130px;
        .incDescWrap {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .descItem1 {
                @include large-text;
                @include orange;
            }

            .descItem2 {
                @include super-text;
                @include font("smb");
                color: #ffffff;
            }
            margin-bottom: 69px;
        }
        .incDescSmalltextWrap {
            .incDescSmalltextItem1 {
                display: flex;
                flex-direction: row;
                gap: 4px;
                @include medium-text;
                @include orange;
                line-height: 23px;
                :nth-child(1) {
                    color: #ffffff;
                }
            }
            .incDescSmalltextItem2 {
                @include medium-text;
                @include orange;
            }
        }

        .incContentWrap {
            width: 100%;
            max-width: 1200px;
            height: 238px;
            background-color: #171738;


            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 5px;

            .incContentContainer{
                padding: 38px 0px 41px 31px;
                display: flex;
                flex-direction: column;
                gap: 47px;
                .incContentItemWrap{
                    height: 56px;
                    display: flex;
                    flex-direction: row;
                    gap: 30px;
                    .incContentItemIcon{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 149px;
                        justify-content: center;
                    }

                    .incContentItemLine{
                        border-left: thick solid #4AB2ED;
                        height: 56px;
                    }
                    .incContentItemLine2{
                        border-left: thick solid #F97447;
                        height: 56px;
                    }
                    .incContentItemText{
                        width: 585px;
                        font-weight: 600;
                        font-size: 18px;
                        @include font("lgt");
                        color: #ffffff;
                    }
                }
                .nftChart img{
                    position: relative;
                    bottom: 180px;
                    right: 30px;
                    width: 100%;
                    height: auto;
                }
                @media screen and (max-width: "1215px") {
                    .nftChart img{
                        display: none;
                    }        
                }
            }
        }
    }
}
