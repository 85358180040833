@font-face {
  font-family: "poppinsLight";
  src: url(../public/font/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: "poppinsMedium";
  src: url(../public/font/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "poppinsRegular";
  src: url(../public/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "poppinsSemibold";
  src: url(../public/font/Poppins/Poppins-SemiBold.ttf);
}

html, body, #root {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

hr{
  margin: 0;
}

/* 이하 생략 */



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */

/* Set core body defaults */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "poppinsLight";
  font-family: "poppinsMedium";
  font-family: "poppinsRegular";
  font-family: "poppinsSemibold";
  /* Box sizing rules */
}

/* Remove list styles on ul, ol elements */
ul[class],
ol[class] {
  list-style: none;
  margin: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 0em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}