@import "../../../../style/mixin.scss";
figure {
  margin: 0;
}


.tabletServiceImage img{
  margin: 0 auto;
}
.tabletServiceDescription {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 70px;
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  .tabletServiceHeader1 {
    @include orange;
    font-size: 24px;
    font-weight: 600;
    line-height: 21px;
  }
  .tabletServiceHeader2 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 600;
    line-height: 40px;
  }
  .tabletServiceDescriptionText {
    margin: 0 auto;
    width: 350px;
    @include orange;
    font-size: 10px;
    line-height: 18px;
    @include font("smb")
  }
  .tabletServiceDescriptionTextKo {
    margin: 0 auto;
    width: 350px;
    @include orange;
    font-size: 10px;
    line-height: 18px;
    @include font("koNotoM")
  }
}

.tabletServiceButtonWrap{
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}