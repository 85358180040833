@import "../../../../../style/mixin.scss";

.mobileIncSection {
  .textArticle {
    padding-left: 24px;
    padding-top: 61px;

    display: flex;
    flex-direction: column;
    flex-wrap: 12px;
    h1 {
      @include orange;
      @include font("smb");
      font-size: 14px;
      line-height: 21px;
    }

    h2 {
      padding-top: 20px;
      @include font("smb");
      font-size: 28px;
      line-height: 42px;
      color: #ffffff;
    }
    .incDescSmalltextWrap {
      padding-top: 20px;
      .incDescSmalltextItem1 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        @include medium-text;
        @include orange;
        line-height: 23px;
        // :nth-child(1) {
        //   color: #ffffff;
        //   height: 10px
        // }
        span.bitblue {
          color: white;
        }
      }
      .incDescSmalltextItem2 {
        @include medium-text;
        @include orange;
      }
    }
    
    @media screen and (min-width: "650px") {
      h1 {
        @include orange;
        @include font("smb");
        font-size: 24px;
        line-height: 21px;
      }

      h2 {
        @include font("smb");
        font-size: 32px;
        line-height: 42px;
        color: #ffffff;
      }
      .incDescSmalltextWrap {
        .incDescSmalltextItem1 {
          display: flex;
          flex-direction: row;
          gap: 4px;
          font-size: 18px;
          @include orange;
          line-height: 23px;
          // :nth-child(1) {
          //   color: #ffffff;
          //   height: 10px
          // }
          span.bitblue {
            color: white;
          }
        }
        .incDescSmalltextItem2 {
          font-size: 18px;
          @include orange;
        }
      }
    }
  }

  .incBox {
    padding: 35px 25px 15px 25px;
    .incBoxContainer {
      width: 100%;
      background-color: #171738;
      border-radius: 10px;
      border: 1px solid #ffffff33;
      padding: 37px 26px 36px 28px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media screen and (max-width: "1100px") and (min-width: "650px") {
        max-width: 800px;
      }

      .incContent {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .incSvgWrap {
          display: flex;
          flex-direction: row;
          gap: 14px;
          align-items: center;
        }

        .incText {
          font-size: 12px;
          @include font("smb");
          color: #ffffff;

          @media screen and (max-width: "1100px") and (min-width: "650px") {
            font-weight: 600;
            font-size: 18px;
            @include font("lgt");
            color: #ffffff;
          }
        }
      }
    }
  }
  height: 1050px;

  img {
    margin: 0 auto;
  }
  @media screen and (max-width: "1100px") and (min-width: "650px") {
    img {
      display: none;
    }
    height: 700px;
  }
}

.incContentItemLine {
  border-left: thick solid #4ab2ed;
  height: 56px;
}
.incContentItemLine2 {
  border-left: thick solid #f97447;
  height: 56px;
}
