

.customerManage{
  width: 100%;
  max-width: 1440px;
  position:relative;
  margin: 0 auto;
  padding: 32px 112px 32px 112px;
  display: flex;
  flex-direction: column;
  gap: 44px;
  height: 110vh;
  @media screen and (max-width: 1550px) {
    padding: 32px 112px 32px 112px;
    height: 110vh
  }

  .tableContainer{
    width: 100%;
    background-color: #ffffff;
    berder-radius: 8px;
    border: 1px solid rgba(26, 26, 26, 0.1);

    .tableWrap{
      padding: 16px 0 16px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      .finger{
        position: absolute;
        top: 100px;
        left: 100px;
      }
      .tableHeader{
        color: var(--tertiary-gray-700-light, #3F3F46);
        /* text-lg/font-semibold */
        font-size: 18px;
        font-family: Pretendard;
        font-weight: 600;
        line-height: 30px;
        padding-left: 20px;
      }
        .pageImage{
          width: 228px;
          height: 48px;
          margin: 0 auto;
        }

        .tableItemContainer > :first-child{
          display: flex;
          flex-direction: row;
          box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8BB7FF;
          border-radius: 8px;
          background-color: #F2F7FF;
          cursor: pointer;
        }
      .tableItemContainer{
        display: flex;
        flex-direction: column;

        .tableItemWrap{
          display: flex;
          flex-direction: row;

          .tableItem{
            width: 100%;
            max-width: 208.3px;
            color: var(--base-900-light, #1A1A1A);
            height: 40px;
            font-size: 14px;
            font-family: Pretendard;
            font-weight: 500;
            line-height: 20px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            border-top: 1px solid rgba(26, 26, 26, 0.1)
          }

        }
      }
    }
  }
}