@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');



@keyframes floating {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes floating2 {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@font-face {
    font-family: "poppinsLight";
    src: url(../../public/font/Poppins/Poppins-Light.ttf);
}

@font-face {
    font-family: "poppinsMedium";
    src: url(../../public/font/Poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: "poppinsRegular";
    src: url(../../public/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: "poppinsSemibold";
    src: url(../../public/font/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: "poppinsbold";
    src: url(../../public/font/Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: "nanumR";
    src: url(../../public/font/nanum/NanumSquareR.ttf);
}
@font-face {
    font-family: "nanumL";
    src: url(../../public/font/nanum/NanumSquareL.ttf);
}

@font-face {
    font-family: "nanumB";
    src: url(../../public/font/nanum/NanumSquareB.ttf);
}

@font-face {
    font-family: "nanumAcEb";
    src: url(../../public/font/nanum/NanumSquare_acEB.ttf);
}

@font-face {
    font-family: "nanumAcR";
    src: url(../../public/font/nanum/NanumSquare_acR.ttf);
}

@font-face {
    font-family: "nanumAcL";
    src: url(../../public/font/nanum/NanumSquare_acL.ttf);
}

@font-face {
    font-family: "preBold";
    src: url(../../public/font/pretendard/Pretendard-Bold.ttf);
}

@font-face {
    font-family: "preR";
    src: url(../../public/font/pretendard/Pretendard-Regular.ttf);
}
@font-face {
    font-family: "preEL";
    src: url(../../public/font/pretendard/Pretendard-ExtraLight.ttf);
}@font-face {
    font-family: "preEB";
    src: url(../../public/font/pretendard/Pretendard-ExtraBold.ttf);
}
@font-face {
    font-family: "preM";
    src: url(../../public/font/pretendard/Pretendard-Medium.ttf);
}
@font-face {
    font-family: "preLight";
    src: url(../../public/font/pretendard/Pretendard-Light.ttf);
}
@font-face {
    font-family: "preSB";
    src: url(../../public/font/pretendard/Pretendard-SemiBold.ttf);
}
@font-face {
    font-family: "preTh";
    src: url(../../public/font/pretendard/Pretendard-Thin.ttf);
}

@mixin floating {
    animation: floating 2s ease-in-out infinite;
}

@mixin floating2 {
    animation: floating2 2s ease-in-out infinite;
}



@mixin orange {
    color: #f97447;
}

@mixin font($bold) {
    @if $bold == "preTh" {
        font-family: "preTh"
    }

    @if $bold == "preSB" {
        font-family: "preSB"
    }

    @if $bold == "preLight" {
        font-family: "preLight"
    }

    @if $bold == "preM" {
        font-family: "preM"
    }

    @if $bold == "preEB" {
        font-family: "preEB"
    }

    @if $bold == "preEL" {
        font-family: "preEL"
    }

    @if $bold == "preR" {
        font-family: "preR"
    }

    @if $bold == "preBold" {
        font-family: "preBold"
    }



    @if $bold == "lgt" {
        font-family: "poppinsLight"
    }

    @if $bold == "mdm" {
        font-family: "poppinsMedium"
    }

    @if $bold == "rgr" {
        font-family: "poppinsRegular"
    }

    @if $bold == "smb" {
        font-family: "poppinsSemibold"
    }

    @if $bold == "bold" {
        font-family: "poppinsbold"
    }

    @if $bold == "nanumL" {
        font-family: "nanumL"
    }

    @if $bold == "nanumB" {
        font-family: "nanumB"
    }

    @if $bold == "nanumR" {
        font-family: "poppinsRegular"
    }
    @if $bold == "nanumAcL" {
        font-family: "nanumAcL"
    }
    @if $bold == "nanumAcR" {
        font-family: "nanumAcR"
    }
    @if $bold == "nanumAcEb" {
        font-family: "nanumAcEb"
    }

    @if $bold == "koNotoM" {
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 500;
    }
    @if $bold == "koNotoB" {
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 700;
    }
    
}

@mixin super-text {
    @content;
    font-family: "poppinsSemibold";
    font-weight: 600;
    font-size: 45px;
}

@mixin large-text {
    @content;
    font-family: "poppinsSemibold";
    font-weight: 600;
    font-size: 28px;
}

@mixin medium-text {
    @content;
    font-family: "poppinsSemibold";
    font-weight: 500;
    font-size: 15px;
}

@mixin languageMenuStyle  {
    width: 100%;
    height: 50%;
    background-color: #ffffff;
    padding-left: 25px;
    display: flex;
    align-items: center;
    transition: background-color 1s ease;
}