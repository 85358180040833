.modalDisabled {
  display: none;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .modalContent {
  width: 384px;
  background-color:#272727;
  border-radius: 12px;
  border: none;
  z-index: 2;
}
