

.finger{
  position: absolute;
  top: 80px;
  right: 80px;
}

.marketSection{
  width: 100%;
  height: 100%;

  .marketHeader{
    width: 100%;
    background-color: #1A1A1A;
    position: fixed;

    .headerItems{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 64px 0px 64px;
      height: 68px;

      .headerProfile{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        box-shadow: 0px 0px 0px 4px #434343, 0px 0px 10px 8px #3b82f6;
        border-radius: 8px;
        padding: 5px 10px 5px 10px;
        width: 102px;
        height: 48px;
        cursor: pointer;
      }

      .headerText{
        display: flex;
        flex-direction: row;
        gap: 44px;
        font-size: 18px;
        font-family: Pretendard;
        font-weight: 500;
        line-height: 30px;
        color: var(--base-700-dark-tertiary, rgba(255, 255, 255, 0.70));
      }
    }
  }

  .space{
    height:68px;
  }
  .jisooBanner {
    background-image: url(../../../../public/image/jisooBanner.png);
    height: 324px;
    background-size: cover;
    background-position: center;
  }

  .madeBy{
    width: 100%;
    background-color: #141414;
    border-top: 1px solid rgba(255, 255, 255, 0.30);

    .madeByWrap{
      padding: 25px 0px 25px 0px;
    }
    .madeByWrap img {
      margin: 0 auto;
    }
  }


  .footer{
    width: 100%;
    background-color: #141414;
    .footerWrap{
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 0 64px 0;
      display:flex;
      flex-direction: column;
      gap: 56px;

      .footerTitleWrap{
        text-align: center;

        .footerTitle{
          color: var(--base-900-dark, #FFF);
          text-align: center;

          /* text-3xl/font-bold$ */
          font-size: 30px;
          font-family: Pretendard;
          font-weight: 700;
          line-height: 42px;
        }

        .footerDesc{
          color: var(--base-700-dark-tertiary, rgba(255, 255, 255, 0.70));
          text-align: center;

          /* text-lg/font-medium$ */
          font-size: 18px;
          font-family: Pretendard;
          font-weight: 500;
          line-height: 30px;
        }
      }

      .footerImage{
        margin: 0 auto;
      }
    }
  }


  .mainContent{
    width: 100%;
    background-color: #141414;

    .singleAlbum{
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 0 64px 0;

      .singleAlbumWrap{
        width: 100%;
        max-width: 1440px;
        padding: 32px 0px 64px 0px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 48px;

        .singleAlbumImg{
          margin: 0 auto;
        }

        .singleAlbumHeader{
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 16px;

          .singleAlbumsubTitle{
            color: var(--rose-500-dark, #FB6F84);
            text-align: center;
            /* text-lg/font-semibold */
            font-size: 18px;
            font-family: Pretendard;
            font-weight: 600;
            line-height: 30px;
          }

          .singleAlbumTitle{
            color: var(--base-900-dark, #FFF);
            text-align: center;

            /* text-5xl/font-bold$ */
            font-size: 48px;
            font-family: Pretendard;
            font-weight: 700;
            line-height: 58px;
          }

          .getBtn{
            border-radius: 8px;
            border: 1px solid var(--rose-600-dark, #FDA5AF);
            background: var(--rose-500-dark, #FB6F84);
            box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.30);
            width: 276px;
            height: 58px;
            margin: 0 auto;
          }

          .singleAlbumsubDesc{
            color: var(--base-700-dark-tertiary, rgba(255, 255, 255, 0.70));
            text-align: center;

            font-size: 18px;
            font-family: Pretendard;
            line-height: 30px;
          }
        }
      }
    }

    .partnerCompany{
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 0 64px 0;

      .partnerCompanyWrap{
        width: 100%;
        max-width: 1440px;
        padding: 64px 0px 64px 0px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 48px;

        .partnerCompanyImage{
          margin: 0 auto;
        }

        .partnerCompanyHeader{
          text-align: center;
          color: var(--base-900-dark, #FFF);
          text-align: center;

          /* text-3xl/font-bold$ */
          font-size: 30px;
          font-family: Pretendard;
          font-weight: 700;
          line-height: 42px;
        }
      }
    }

    .roadmap{
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 0 64px 0;

      .roadmapWrap{
        width: 100%;
        max-width: 1440px;
        padding: 64px 0px 64px 0px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 48px;

        .roadmapImage{
          margin: 0 auto;
        }

        .roadmapHeader{
          text-align: center;
          color: var(--base-900-dark, #FFF);
          text-align: center;

          font-size: 30px;
          font-family: Pretendard;
          font-weight: 700;
          line-height: 42px;
        }
      }
    }

    .flowerSerenade{
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 64px 0 64px 0;

      .jisooCardWrap img{
        margin: 0 auto;
      }

      .flowerSerenadeTextWrap{
        padding: 64px 0px 32px 64px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: center;

        .flowerSerenadeTitle{
          color: var(--base-900-dark, #FFF);
          text-align: center;
          font-size: 48px;
          font-family: Pretendard;
          font-weight: 700;
          line-height: 58px;
        }
        .flowerSerenadeTextDesc{
          color: var(--base-700-dark-tertiary, rgba(255, 255, 255, 0.70));
          text-align: center;
          font-size: 18px;
          font-family: Pretendard;
          font-weight: 500;
          line-height: 30px;
        }
        .connectedBtn {
          border-radius: 8px;
          width: 212px;
          height: 58px;
          border: 1px solid var(--rose-600-dark, #FDA5AF);
          background: var(--rose-500-dark, #FB6F84);
          margin: 0 auto;
        }

        box-shadow: 0px 1px 1px 0px rgba(18, 18, 18, 0.30);
      }
    }

    .schedule{
      width: 100%;
      background-color: #141414;
      .scheduleWrap{
        width: 100%;
        max-width: 1440px;
        padding: 64px 0px 64px 0px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-direction: row;
        gap: 48px;
      }
    }

    .jisooBenefit{
      width: 100%;
      background-color: #141414;

      .jisooBenefitWrap{
        width: 100%;
        max-width: 1440px;
        padding: 64px 0px 64px 0px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-direction: row;
        gap: 48px;

        .jisooBenefitInfo{
          display: flex;
          flex-direction: column;
          gap: 32px;

          .benefitHeader{
            display: flex;
            flex-direction: column;
            gap: 12px;
            .benefitTitle{
              color: var(--base-900-dark, #FFF);
              font-size: 30px;
              font-family: Pretendard;
              font-weight: 700;
              line-height: 42px;
            }
            .benefitDesc{
              color: var(--base-700-dark-tertiary, rgba(255, 255, 255, 0.70));

              /* text-lg/font-regular$ */
              font-size: 18px;
              font-family: Pretendard;
              line-height: 30px;
            }
          }
          .benefitDetails{
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
        }
      }
    }
  }
}