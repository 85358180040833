@import "../../../style/mixin.scss";

.dashboardArticle {
  padding: 32px 0 64px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media screen and (max-width: 1550px) {
    flex-direction: column;
    padding: 60px;
  }

  .finger{
    position: absolute;
    top: 110px;
    left: 290px;
    transform: rotate(-90deg);
  }
  .menuOverWrap{
    width: 232px;
    height: 48px;
    box-shadow: 0px 0px 0px 3px #2E88F6, 0px 0px 10px 6px #8BB7FF;
    position: absolute;
    left: 8px;
    top: 130px;
    border-radius: 8px;
    cursor: pointer;
  }


    .nftDealSituation{
      width: 480px;
      height: 400px;

      background-color: #ffffff;
      border-radius: 10px;
      border: 1px solid rgba(26, 26, 26, 0.1);
      display: flex;
      flex-direction: column;
      gap: 24px;

      .nftDealSituationWrap{
        padding: 16px 16px 16px 16px;

        .nftDealSituationTitleWrap{
          padding: 16px 16px 16px 16px;
          @include font("preSB");
          font-size: 18px;
          color: #000000;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  .dashboardLeftContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media screen and (max-width: 1550px) {
      flex-direction: row;
    }


    .customerSituation {
      width: 480px;
      height: 472px;
      @media screen and (max-width: 16000px) {
        width: 415px;
        height: 450px;
      }

      background-color: #ffffff;
      border-radius: 10px;
      border: 1px solid rgba(26, 26, 26, 0.1);
      display: flex;
      flex-direction: column;
      gap: 24px;

        .customerSituationStatsImage{
          padding: 0px 24px 0px 24px;
        }
      .customerSituationWrap {
        padding: 16px 16px 16px 16px;
        display: flex;
        flex-direction: column;
        gap: 21px;

        .customerSituationContent{
          display: flex;
          flex-direction: row;
          gap: 25px;
          padding: 0px 24px 0px 24px;
        }
        .customerSituationContentBox{
          display: flex;
          flex-direction: column;
          width: 125px;
          border-right: 1px solid rgba(26, 26, 26, 0.1);

          .customerSituationContentBoxTitle{
            @include font("preM");
            font-size: 14px;
            color: #878787;
          }

          .customerSituationContentBoxValue{
            @include font("preBold");
            font-size: 24px;
            color: #141414;
          }
        }
        .customerSituationContentBox2{
          display: flex;
          flex-direction: column;
          width: 125px;
          .customerSituationContentBoxValue{
            @include font("preBold");
            font-size: 24px;
            color: #141414;
          }
          .customerSituationContentBoxTitle{
            @include font("preM");
            font-size: 14px;
            color: #878787;
          }
        }

        .customerSituationTitle {
          padding: 16px 0px 16px 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include font("preSB");
          font-size: 18px;
          color: #000000;
        }
      }
    }
  }

  .dashboardRightContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 928px;

    .dashboardRightWrap {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;

      .dashboardBoxContainer {
        display: flex;
        flex-direction: row;
        gap: 32px;
      }
    }

    .dashboardRightStatsContainer {
      background-color: #ffffff;
      border-radius: 10px;
      border: 1px solid rgba(26, 26, 26, 0.1);
      height: 400px;
      .dashboardRightStatsWrap {
        padding: 16px 16px 16px 16px;

        .dashboardRightStats {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          @include font("preSB");
          font-size: 18px;
          color: #000000;
        }

      }
    }
  }
}
