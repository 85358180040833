$orange: #f97447;
@import "../../style/mixin.scss";

.companyInfoWrap {
  width: 100%;
  height: 900px;
  @media screen and (max-width: 1131px) {
    height: 1200px;
  }
  @media screen and (max-width: 750px) {
    height: 1650px;
  }
  @media screen and (max-width: 650px) {
    height: 1650px;
  }


  .companyInfoTopback {
    width: 100%;
    height: 565px;
    @media screen and (max-width: 750px) {
      height: 1250px;
    }
    @media screen and (max-width: 650px) {
      background-image: url("../../../public/image/mobileserviceback.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 820px;
    }
    background-color: #08081f;
  }
  .mobileCompanyInfoHeaders{
    padding-top: 80px;
    color: #ffffff;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .mobileCompanyInfoHeader1{
      @include orange;
      @include font("smb");
      font-size: 14px;
    }
    .mobileCompanyInfoHeader2{
      font-size: 28px;
      @include font("smb")
    }
    .mobileCompanyInfoDesc{
      @include orange;

      font-size: 14px;
      line-height: 24px;
      @include font("koNotoM");
      font-weight: 500;
    }
  }

  .companyInfoHeaders {
    padding-top: 123px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    .companyInfoHeader1 {
      color: $orange;
      @include large-text;
      padding-left: 24px;
    }
    .companyInfoHeader2 {
      color: #ffffff;
      @include super-text;
      padding-left: 24px;
    }

    .companyInfoDesc {
      padding-left: 24px;
      color: $orange;
      @include medium-text;
      line-height: 30px;
    }

    .companyInfoDescKo{
      padding-left: 24px;
      color: $orange;
      @include font("koNotoM");
      font-weight: 500;
      font-size: 15px;
      line-height: 30px;
    }

    @media screen and (max-width: 450px) {
      .companyInfoHeader1 {
        color: $orange;
        @include font("smb");
        font-size: 14px;
        padding-left: 24px;
      }
      .companyInfoHeader2 {
        color: #ffffff;
        @include font("bold");
        font-size: 24px;
        padding-left: 28px;
      }

      .companyInfoDesc {
        padding-left: 24px;
        color: $orange;
        @include font("smb");
        font-size: 12px;
        line-height: 30px;
        width: 70%;
      }
      text-align: inherit;
    }

  }
  .companyInfoCards {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    position: relative;
    bottom: 160px;
    display: flex;
    gap: 56px;
    flex-direction: row;
    justify-content: center;
  }

  .companyInfoBottomBack {
    position: relative;
    bottom: 140px;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    height: 417px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1131px) {
      width: 90%;
      padding: 0px 20px 0px 20px;
      bottom: 160px;
    }
    @media screen and (max-width: 750px) {
      width: 90%;
      padding: 0px 20px 0px 20px;
      bottom: 850px;
    }

    @media screen and (max-width: 650px) {
      bottom: 500px;
    }
    @media screen and (min-width: 595px) and (max-width: 650px) {
      bottom: 400px;
    }

    .companyInfoCheckList {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 1260px;
      .checkListWrap {
        display: flex;
        flex-direction: column;
        @include font("mdm");
        font-size: 12px;
        line-height: 18px;
        gap: 30px;
      }

      .checkListContainer {
        display: flex;
        flex-direction: row;
        gap: 40px;
        line-height: 25px;
        .checkListText{
          font-size: 15px;
          @include font("smb")
        }
        .checkListTextKo{
          font-size: 15px;
          @include font("koNotoM")
        }

      }
    }
  }

  ul {
    padding-left: 48px;
    line-height: 1.5;
    li {
      margin-bottom: 30px;
    }
  }

  background-color: #f5f5f5;
}
