@import "../../../style/mixin.scss";

footer {
  height: 480px;
  width: 100%;

  background-color: #030523;

  .footerSection {
    .titleArticle {
      display: flex;
      padding: 47px 24px 0px 24px;
      flex-direction: column;
      justify-content: space-between;

      .footerLogo {
        display: flex;
        width: 100%;
        max-width: 650px;
        height: 100%;
        align-items: center;
        justify-content: space-between; /* 추가 */

        .iconContainer {
          display: flex;
          align-items: center;
          gap: 5px;

          .iconCircle {
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 100%;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .footerTitle {
        @include orange;
        font-size: 10px;
        @include font("rgr");
      }
    }
    .footerInfo {
      padding: 43px 24px 0px 24px;
      .companyInfo {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 40px;
        @media screen and (max-width: "400px") {
          gap: 20px;
        }

        .infoList {
          white-space: nowrap;
          display: flex;
          gap: 13px;
          flex-direction: column;
          :nth-child(1) {
            font-family: "poppinsLight";
            font-weight: 600;
            font-size: 12px;
            line-height: 27px;
            color: #ffffff;
          }
          font-family: "poppinsLight";
          font-weight: 600;
          font-size: 10px;
          line-height: 27px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .bitblueInfo{
      text-align: center;
      height: 120px;
      color: #FFFFFF99;
      @include font("rgr");
      font-size: 10px;
      line-height: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 18px;
      :nth-child(1) {
        @include orange;
        @include font("rgr");
        font-size: 10px;
        line-height: 30px;
      }
    }
  }
}
