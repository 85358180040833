@import "../../../../../style/mixin.scss";

.leadershipWrap {
  width: 100%;
  height: 884px;

  background-color: #ffffff;

  .textWrap{
    width: 100%;
    max-width: 1260px;
    padding-top: 257px;
    margin: 0 auto;

    .textHeader {
      @include large-text;
      @include orange;
    }
    
    .textDesc{
      width: 100%;
      max-width: 880px;
      padding-top: 35px;
      font-size: 18px;
      font-weight: 500;
      @include font("smb");
      line-height: 32px;
    }

    .textDescKo{
      width: 100%;
      max-width: 880px;
      padding-top: 35px;
      font-size: 18px;
      font-weight: 500;
      @include font("koNotoM");
      line-height: 32px;
    }
  }

  .leaderInfoWrap{
    width: 100%;
    display: flex;
    justify-content: center;  
    gap: 124px;
  }
}