.companyInfoCards {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
  bottom: 250px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 60px;

  @media screen and (max-width: "1131px") {
    bottom: 200px;
  }


  @media screen and (max-width: "750px") {
    bottom: 900px;
  }

  @media screen and (max-width: "650px") {
    bottom: 550px;
  }

}