@import "../../style/mixin.scss";

.layoutMain{
  overflow-y: hidden;
}

.mainHeaderWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .mainHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    padding-left: 64px;
    gap: 12px;
    .mainHeader {
      @include font("preBold");
      font-size: 20px;
      line-height: 32px;
    }

    .mainHeaderSubItems{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .mainHeaderSubNavItem{
        @include font("preSB");
        font-size: 20px;
        line-height: 30px;
        color: #898A8A;
        border-bottom: 1px solid #898A8A;
      }
    }

      .mainHeaderReload {
        margin-top: 6px;
      }
  }

  .mainHeaderProfile{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    padding-right: 152px;

    .mainHeaderProfileCompanyProfileInfo{
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .mainHeaderProfileDetail{
        display: flex;
        flex-direction: column;
        .mainHeaderProfileDetailName{
        font-size: 14px;
        color: #000000;
        line-height: 24px;
        @include font("preM");
        }
        .mainHeaderProfileDetailContact{
          font-size: 14px;
          color: rgba(26, 26, 26, 0.5);
          line-height: 24px;
          @include font("preM");
        }
      }
    }

    .mainHeaderProfileBellIcon{
      width: 36px;
      height: 36px;
      border-radius: 10px;
      border: 1px solid #E4E4E7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
