@import "../../style/mixin.scss";

.workTextCheckList {
    .checkListWrap {
        display: flex;
        gap: 31px;
        @media screen and (max-width: 650px) {
            height: 200px;
            align-items: center;
            gap: 24px;
            @include font("mdm");
        }
    }

    .orangeCircle {
        width: 111px;
        max-height: 111px;
        border-radius: 100%;
        background-color: #F97447;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        @include font("smb");
        font-weight: 600;
        color: #ffffff;
        margin-top: 4px;
        @media screen and (max-width: 650px) {
            width: 72px;
            height: 72px;
            font-size: 14px;
        }
    }

    .iconWrap {
        margin-top: 2px;
    }

    .listWrap {
        @media screen and (max-width: 650px) {
            margin-top: 20px;
        }

        .checkListContainer {
            display: flex;
            flex-direction: row;
            gap: 13px;
            line-height: 40px;
            @include font("mdm");
            @media screen and (max-width: 650px) {
                width: 100%;
                max-width: 160px;
                font-size: 12px;
                gap: 10px;
                line-height: 20px;
            }
        }

        .checkListContainerKo{
            display: flex;
            flex-direction: row;
            gap: 13px;
            line-height: 40px;
            @include font("koNotoB");
            @media screen and (max-width: 650px) {
                width: 100%;
                max-width: 160px;
                font-size: 12px;
                gap: 10px;
                line-height: 20px;
            }
        }
    }
}