@import "../../style/mixin.scss";

.workWrap {
    width: 100%;
    height: 1170px;
    background-color: #F5F5F5;
    @media screen and (max-width: 1200px){
        background-color: #F5F5F5;  
        height: 1100px;         
    }
    @media screen and (max-width:650px){
        background-color: #F5F5F5;  
        height: 950px;         
    }

    
    .workContainer {
        width: 100%;
        max-width: 1260px;
        margin: 0 auto;
        
        .workHeaders {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-top: 122px;
            @include font("lgt");
            max-width: 1150px;
            white-space: normal;
            .workHeaders1 {
                @include large-text;
                @include orange;
            }
            
            .workHeaders2 {
                @include super-text;
            }
            
            .workHeaders3 {
                @include medium-text;
                @include orange;
            }
            @media screen and (max-width: 1200px){
                padding-left: 24px;
            }
            @media screen and (max-width: 650px){
                padding-top: 70px;
                padding-right: 10px;
                .workHeaders1 {
                    @include font("smb");
                    font-size: 14px;
                }

                .workHeaders2 {
                    @include font("smb");
                    font-size: 28px;
                }

                .workHeaders3 {
                    font-size: 12px;
                    @include orange;
                    @include font("smb");
                }
            }
        }
        .workContentsWrap {
            width: 100%;
            max-width: 1260px;
            height: 556px;
            margin-top: 60px;
            background-color: rgba(255, 255, 255, 0.2);

            .workContentsContainer {
                display: flex;
                flex-direction: row;
                padding: 57px 63px 0px 48px;
                border-radius: 10px;
                background-color: #ffffff;
                align-items: center;
                @media screen and (max-width: 1100px) {
                    justify-content: center;

                    border-radius: 10px;
                    margin: 0px 46px 46px 46px;
                    padding: 20px 28px 0px 28px;

                    background-color: #ffffff;
                }
                @media screen and (max-width: 650px) {
                    //justify-content: center;

                    border-radius: 10px;
                    margin: 0px 24px 24px 24px;
                    padding: 20px 28px 0px 28px;

                    background-color: #ffffff;
                }

                .imageWrap img {
                    width: 100%;
                    height: auto;
                    @media screen and (max-width: 1100px) {
                        display: none;
                    }
                }

                .workText {
                    width: 600px;
                    :nth-child(3) {
                        padding-bottom: 68px;
                    }
                    @media screen and (max-width: 650px) {
                        padding-bottom: 30px;
                        :nth-child(3) {
                            padding-bottom: 0px;
                        }
                    }
                    .workTextHeader {
                        @include font("smb");
                        font-size: 38px;
                        font-weight: 600;
                        padding-bottom: 50px;
                        margin-top: 28px;
                        @media screen and (max-width: 650px) {
                            font-size: 18px;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}
