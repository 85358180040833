@import "../../../../../style/mixin.scss";

.mobileLeadershipWrap {
  width: 100%;
  height: 680px;
  background-color: #ffffff;
  @media screen and (max-width: "1100px") and (min-width: "650px") {
    height: 500px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mobileTextWrap{
    padding-left: 24px;
    padding-right: 24px;

    .mobileTextHeader {
      @include large-text;
      @include orange;
    }
    
    .mobileTextDesc{
      width: 100%;
      max-width: 880px;
      padding-top: 35px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      @include font("mdm")
    }
  }

  .mobileLeaderInfoWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 54px;

    @media screen and (max-width: "1100px") and (min-width: "650px") {
      flex-direction: row;
      gap: 50px;
    }
    @media screen and (max-width: "550px") {
      flex-direction: column;
      gap: 42px;
    }

    & > * {
      margin-bottom: 20px;
    }

    .Profile {
      width: 100%;
      max-width: 400px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}