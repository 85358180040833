@import "../../../style/mixin.scss";

.layoutMain{
  display: flex;
  flex-direction: row;
  height: 100%;

  .layoutContentWrap{
    width: 100%;
    background-color: rgb(247, 249, 250);
  }

  .layoutContent{
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 100%;

    .layoutHeader{
      height: 80px;
    }
  }
}

.layoutSideNav{
  width: 256px;
  flex: 1;
  background-color: #000000;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .layoutSideNavLogo{
    padding: 24px 51px 37px 51px
  }
  .layoutSideNavWrap{
    display: flex;
    flex-direction: column;

    .layoutSideNavItemWrap{
      display: flex;
      flex-direction: row;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      padding: 14px 23px 14px 20px;
      cursor: pointer;

      .layoutSideNavItems{
        display: flex;
        flex-direction: row;
        gap: 15px;
        .layoutSideNavItemIcon{
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .layoutSideNavTitleActive{
          color: #ffffff;
          @include font("preBold");
          font-size: 16px;
        }
        .layoutSideNavTitle{
            color: #D1D5DB;
          @include font("preBold");
          font-size: 16px;
        }
      }
    }
  }
}