@import "../../../style/mixin.scss";

.newModalAddBtnWrap{
  position: relative;
}
.finger{
  position: absolute;
  transform: rotate(180deg);
  right: 80px;
  bottom: 50px;
}
.finger2{
  position: absolute;
  right: 150px;
  top: 110px;
}

.newModal {
  width: 384px;
  height: 452px;
  background-color: #ffffff;
  border: none;
  border-radius: 12px;

  .newModalContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
      .saveBtnWrap{
        width: 100%;

        .saveBtn{
          width: 352px;
          height: 44px;
          border: 1px solid #1D4FD7;
          background-color: #2463EB;
          border-radius: 8px;
          color: #ffffff;
          @include font("preSB");
          font-size: 16px;
          box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8bb7ff;
          cursor: pointer;
        }
      }

    .newModalContent{
      display: flex;
      flex-direction: column;
      gap: 16px;

      .newModalAddBtn{
        background-color: #F4F4F5;
        border-radius: 8px;
        border: 1px solid #E4E4E7;
        width: 81px;
        height: 36px;
        @include font("preSB");
      }

      .newModalContentItem{
        display: flex;
        flex-direction: column;
        gap: 8px;


        .newModalInputContainer{
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .newModalContentItemHeader{
          display: flex;
          flex-direction: row;

          .newModalContentItemHeaderItem {
            width: 100%;
            @include font("preM");
            color: rgba(26, 26, 26, 0.7);
          }
        }

        .newModalInputWrap{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .newModalInput{
            width: 148px;
            display: flex;
            flex-direction: row;
            border: 1px solid #E4E4E7;
            border-radius: 8px;

            .newModalInputItem{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 6px 12px 6px 12px;
              color: rgba(26,26,26, 0.5);
              @include font("preM");
            }
          }
        }
      }

      .newModalTapWrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        height: 36px;
        .newModalTapTitle1{
          width: 100%;
          border-bottom: 2px solid #2463EB;
        }

        .newModalTapTitle2{
          width: 100%
        }
      }
    }
    .newHeaderWrap {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .newHeaderTitle {
        @include font("preBold");
        font-size: 24px;
        color: #1a1a1a;
      }

      .newHeaderDesc {
        color: rgba(26, 26, 26, 0.7);
        @include font("preR");
        font-size: 16px;
      }
    }
  }
}

.newRegistArticle {
  margin: 32px auto 32px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(26, 26, 26, 0.1);
  width: 100%;
  max-width: 1440px;

  .newRegistWrap {
    padding: 64px 48px 48px 64px;

    .newRegistContentInfo {
      display: flex;
      flex-direction: column;

      .newRegistContentInfoDetails {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(26, 26, 26, 0.1);
        border-radius: 8px;

        .newRegistContentInfoDetailsItemWrap {
          padding: 20px 26px 20px 26px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .newRegistContentInfoDetailsItem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include font("preM");
            color: #878787;
            font-size: 16px;
          }
        }
      }
      .newRegistContentInfoImageWrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .newRegistContentInfoImageDisplay {
          @include font("preSB");
          font-size: 16px;
        }
      }
      .newRegistContentInfoDesc {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .newRegistContentInfoDescContainer {
        border-bottom: 1px solid rgba(26, 26, 26, 0.1);
        border-right: 1px solid rgba(26, 26, 26, 0.1);
        border-left: 1px solid rgba(26, 26, 26, 0.1);
        border-radius: 8px;
      }

      .newRegistContentInfoDescWrap {
        padding: 26px 20px 26px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .newRegistContentInfoDescTitleWrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .newRegistContentInfoDescTitle {
            background-color: #f6f6f6;
            width: 90px;
            height: 28px;
            border-radius: 8px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .newRegistContentInfoDescContent {
          display: flex;
          flex-direction: column;
          gap: 20px;
          @include font("preBold");
          color: #000000;
          font-size: 20px;
        }
      }
    }

    .newRegistContentWrap {
      display: flex;
      flex-direction: row;
      gap: 60px;
    }

    .newRegistTitle {
      color: #000000;
      font-size: 30px;
      @include font("preBold");
    }

    .newRegistInputWrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 19px;
      padding-top: 104px;
      position: relative;
      .newRegistInputBtnCancel {
        width: 225px;
        height: 58px;
        border-radius: 8px;
        background-color: #e4e4e7;
        border: none;
        @include font("preSB");
      }
      .newRegistInputBtnSave {
        width: 225px;
        height: 58px;
        border-radius: 8px;
        background-color: #2463eb;
        color: #ffffff;
        @include font("preSB");
        border: none;
        cursor: pointer;
        box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8bb7ff;
      }
    }

    .newRegistImg img {
      width: 100%;
      max-width: 908px;
    }
  }
}
