@import "../../../../style/mixin.scss";

.serviceContainer {
  width: 100%;
  max-width: 1260px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  padding-top: 20px;

  .serviceText {
    padding-top: 176px;
    width: 100%;
    max-width: 630px;

    .header1 {
      @include large-text;
      line-height: 50px;
      white-space: nowrap;
      color: #f97447;
    }

    .header2 {
      font-family: "poppinsMedium";
      font-weight: 600;
      font-size: 64px;
      padding-top: 20px;
      white-space: nowrap;
      line-height: 50px;

      color: #ffffff;
    }

    .subText {
      @include medium-text;
      line-height: 23px;
      padding-top: 20px;

      color: #f97447;
    }

    .subTextKo {
      @include font("koNotoM");
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
      padding-top: 20px;

      color: #f97447;
    }

    .serviceImage {
      width: 100%;
      max-width: 630px;
      > img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .serviceBtnWrap {
    display: flex;
    flex-direction: row;
    padding-top: 57px;
    gap: 30px;
  }
}
