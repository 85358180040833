@import "../../../../style/mixin.scss";
figure {
  margin: 0;
}

.mobileService {
  width: 100%;
  height: 754px;
  background-image: url("../../../../../public/image/nftmachinmonile.png");
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: bottom;

  .mobileContent {
    display: flex;
    flex-direction: column;
    padding-top: 290px;
    padding-left: 24px;
    max-width: 260px;

    .mobileHeader {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include font("smb");
      h1.mobileTitle {
        @include orange;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      h2.mobileSubtitle {
        font-size: 32px;
        color: #ffffff;
        font-weight: 600;
        line-height: 40px;
        padding-bottom: 12px
      }
    }

    .mobileDescription {
      p {
        @include orange;
        @include font("smb");
        font-size: 12px;
        line-height: 18px;
      }
    }
    .mobileDescriptionKo {
      p {
        @include orange;
        @include font("koNotoB");
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .mobileButtons {
    padding-top: 38.5px;
    padding-left: 24px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
