@import "../../../../style/mixin.scss";

.visionback{
    background-image: url(../../../../../public/image/cityback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 336px;
    text-align: center;
    @media screen and (max-width: "730px"){
        height: 208px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../../../../../public/image/cityback.png);
    }
    .headerWrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        
        .header1{
            @include large-text;
            @include orange;
            padding-top: 72px;
        }
        .header2{
            display: flex;
            justify-content: center;
            gap: 10px;
            padding-top: 20px;
            
            
            :nth-child(1) {
                font-family: "poppinsSemibold";
                font-weight: 500;
                font-size: 22px;
                color: #ffffff;
                line-height: 48px;
            }
            :nth-child(2) {
                @include large-text;
                color: #ffffff;
            }
            :nth-child(3){
                font-family: "poppinsSemibold";
                font-weight: 500;
                font-size: 22px;
                color: #ffffff;
                line-height: 48px;
                color: #ffffff;
                line-height: 48px;
            }
        }

        .header2Ko{
            display: flex;
            justify-content: center;
            gap: 10px;
            padding-top: 20px;


            :nth-child(1) {
                @include font("koNotoM");
                font-weight: 500;
                font-size: 22px;
                color: #ffffff;
                line-height: 48px;
            }
            :nth-child(2) {
                @include large-text;
                color: #ffffff;
                @include font("koNotoM");
            }
            :nth-child(3){
                @include font("koNotoM");
                font-weight: 500;
                font-size: 22px;
                color: #ffffff;
                line-height: 48px;
                color: #ffffff;
                line-height: 48px;
            }
        }
        
        .header3{
            @include large-text;
            color: #ffffff;
            // padding-top: 72px;
        }
        @media screen and (max-width: "730px"){
            .header1{
                font-size: 14px;
                @include orange;
                padding-top: 0px;
            }
            .header2{
                display: flex;
                justify-content: center;
                gap: 5px;
                padding-top: 20px;
                :nth-child(1) {
                    @include font("smb");
                    font-weight: 500;
                    font-size: 10px;
                    color: #ffffff;
                    line-height: 0px;
                }
                :nth-child(2) {
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 0px;
                }
                :nth-child(3){
                    @include font("smb");
                    font-weight: 500;
                    font-size: 10px;
                    color: #ffffff;
                    line-height: 0px;
                }
            }
            .header3{
                font-size: 12px;
                color: #ffffff;
                line-height: 30px;
            }
        }
    }
}