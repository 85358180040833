.mobileHeaderWrap{
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #030523;
  
  .mobileContainer{
      width: 100%;
      max-width: 650px;
      margin: 0 auto;
      
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 24px;

    .mobilelogoWrap{
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
      .menuIcon{
          cursor: pointer;
      }

      .mobileHeaderLogo{
          cursor: pointer;
      }
  }
}
