@import "../../../style/mixin.scss";

.loginContainer{
  position: relative;

  .loginHeader{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    padding-left: 40px;
    height: 80px;
  }

  .loginArticle{
    width: 100%;
    height: calc(100vh - 110px);
    background-color: rgb(247, 249, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-height: 1550px) {
      height: calc(100vh - 80px);
    }

    .loginFooter{
      position: absolute;
      width: 100%;
      top: 93%;
      text-align: center;
      @media screen and (max-height: 800px) {
        display: none;
      }
    }

    .loginContent{
      margin: 0 auto;
      background-color: #FDFDFD;
      width: 100%;
      max-width: 588px;
      height: 568px;
      border-radius: 12px;
      border: 1px solid #E4E4E7;
      @media screen and (max-height: 800px) {
        height: 480px;
      }

      .loginContentInner{
        padding: 24px 32px 32px 32px;
        display: flex;
        flex-direction: column;
        gap: 45px;
        @media screen and (max-height: 800px) {
          gap: 25px;
        }

        .loginContentInnerHeader{
          display: flex;
          flex-direction: column;
          gap: 16px;

          @media screen and (max-height: 800px) {
            gap: 8px;
          }

          .loginContentInnerHeaderTitle{
            @include font("preBold");
            font-size: 24px;
            line-height: 34px;
          }

          .loginContentInnerHeaderSubTitle{
            @include font("preR");
            font-size: 18px;
            color: rgba(26,26,26, 0.7)
          }
        }

        .loginContentInnerInputs{
          display: flex;
          flex-direction: column;
          gap: 24px;
          @media screen and (max-height: 800px) {
            gap: 8px;
          }
          
          .loginContentInnerInputWrap{
            width: 100%;
            max-width: 524px;
            height: 58px;
            border: 1px solid #E4E4E7;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .loginContentInnerInput{
              width: 100%;
              border: none;
              padding: 13px 16px 13px 16px;
              outline: none;
              color: rgba(26,26,26, 0.5);
              @include font("preSB");
            }

            .loginContentInnerInputEye{
              cursor: pointer;
              padding-right: 16px;
            }
          }
        }

        .loginContentInnerLoginBtnWrap{
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 16px;
          position: relative;
        }
        .loginContentInnerForgotAccount{
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 5px;
          line-height: 44px;

          .loginContentInnerForgotAccountTitle{
            color: rgba(26,26,26, 0.7);
            @include font("preSB");
          }
          .loginContentInnerForgotAccountSubTitle{
            color: #000000;
            @include font("preBold");
            cursor: pointer;
          }
        }
        .loginContentInnerLoginBtn{
          color: #FFFFFF;
          @include font("preSB");
          background-color: #1D4FD7;
          width: 100%;
          max-width: 524px;
          height: 58px;
          border: 1px solid #1D4FD7;
          border-radius: 8px;
          box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8BB7FF;
          cursor: pointer;
        }
      }
    }
  }
}