@import "../../style/mixin.scss";

.dashboardContainer {
  width: 448px;
  height: 220px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid rgba(26, 26, 26, 0.1);

  .dashboardWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 16px 16px 16px;

    .boxHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000000;
      @include font("preBold");
      font-size: 18px;
      line-height: 30px;
      align-items: center;
    }
    .line {
      border: 1px solid rgba(26, 26, 26, 0.1);
    }
    .boxContent {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .contentLine {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        height: 33px;
        .item1{
          @include font("preM");
          font-size: 16px;
          line-height: 24px;
        }
        .itemsWrap {
          display: flex;
          flex-direction: row;
          gap: 9px;
          align-items: center;

          .item2{
            @include font("preBold");
            font-size: 20px;
          }

          .item3{
            @include font("preM");
            font-size: 14px;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
}
