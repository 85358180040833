.companyLeadershipBack {
    width: 100%;
    height: 2557px;
    // background-image: url(../../../public/image/companyincbak.png);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    background-color:
        #030523;
    @media screen and (max-width: "1100px") {
        height: 2050px;
    }
    @media screen and (max-width: "850px") {
        height: 1950px;
    }
    @media screen and (max-width: "750px") {
        height: 1750px;
    }

    @media screen and (max-width: "650px") {
        height: 2380px;
    }
}