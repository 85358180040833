@import "../../style/mixin.scss";

.InputLine {
  display: grid;
  grid-template-rows: minmax(20px, min-content) 1fr;
  row-gap: 8px;

  &.ReadOnly {
    opacity: 0.5;
  }

  &.EmptyLabel {
    grid-template-rows: 1fr;

    > .InputLineBody {
      grid-row: 1;
    }

    > .InputLineFooter {
      grid-row: 2;
    }
  }

  > .InputLineHeader {
    text-align: left;
    line-height: 20px;
    align-items: center;

    > label {
      color: rgba(26, 26, 26, 0.7);
      @include font("preSB");
      font-size: 18px;
      line-height: 30px;
    }


    > .InputLineRight {
      font-size: 10px;
      font-weight: 400;
      text-align: right;
      margin-left: 20px;
      color: #606060;
      @include font("preSB");
      font-weight: bold;
    }
  }

  > .InputLineBody {
    grid-row: 2;
  }

  > .InputLineFooter {
    grid-row: 3;
  }
}
