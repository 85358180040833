@import "../../../style/mixin";


.projectArticle{
  padding: 32px 112px 64px 112px;
  height: 100vh;
  width: 100%;
  max-width: 1440px;
  margin: 32px auto;
  position: relative;
  .finger{
    position: absolute;
    right: 305px;
    top: 10px;
    transform: rotate(90deg);
  }

  .projectWrap{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .projectHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .projectTitle{
    @include font("preBold");
    font-size: 30px;
    color: #000000;
    }

    .projectButton{

      color: #FFFFFF;
      @include font("preSB");
      background-color: #1D4FD7;
      width: 100%;
      max-width: 146px;
      height: 51px;
      border: 1px solid #1D4FD7;
      border-radius: 8px;
      box-shadow: 0px 0px 0px 3px #ffffff, 0px 0px 10px 6px #8BB7FF;
      cursor: pointer;
    }
  }
}
