.finger {
  position: absolute;
  left: 160px;
  top: 30px;
}

.finger2 {
  position: absolute;
  top: 160px;
  transform: rotate(-90deg);
  left: 300px;
}

.menuOverWrap{
  width: 232px;
  height: 48px;
  box-shadow: 0px 0px 0px 3px #2E88F6, 0px 0px 10px 6px #8BB7FF;
  position: absolute;
  left: 8px;
  top: 180px;
  border-radius: 8px;
  cursor: pointer;
}

.detailArticle {
  background-color: #ffffff;

  .detailWrap {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding-top: 80px;
    height: 100vh;
    @media screen and (max-width: 1500px) {
      padding: 60px 60px 60px 60px;
      max-width: 900px;
    }

    .detailFilter{
      display: flex;
      flex-direction: row;
      .detailFilterTitle {
        width: 95px;
        border-right: 1px solid rgba(26, 26, 26, 0.1) ;
      }

      .filterBtn{
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(26, 26, 26, 0.3);
        width: 86px;
        height: 40px;
      }

    }
    .detailHeader {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .detailProfileInfo {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .detailProfileEmail {
          color: var(--base-00-dark-primary, #141414);
          font-size: 24px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 34px;
        }

        .detailInfoDesc {
          border: 2px solid rgba(26, 26, 26, 0.1);
          width: 135px;
          height: 38px;
          border-radius: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #555;
          font-size: 14px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 14px;
        }
      }
    }

    .detailContent {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;

      .detailContentHeader {
        display: flex;
        flex-direction: row;
        gap: 15px;
        border-bottom: 1px solid rgba(26, 26, 26, 0.1);
        cursor: pointer;

        .favItemWrap{
          display: flex;
          flex-direction: row;
          gap: 6px;
          color: var(--base-700-light-tertiary, rgba(26, 26, 26, 0.70));
          text-align: center;

          font-size: 18px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 30px;

          .favItemNumber{
            background-color: #E4E4E7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: #1A1A1AB2;
            height: 24px;
            width: 30px;
          }

        }

        .favProjectWrap {
          display: flex;
          flex-direction: row;
          gap: 6px;
          color: var(--base-700-light-tertiary, rgba(26, 26, 26, 0.70));
          text-align: center;

          font-size: 18px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 30px;

          .favProjectNumber {
            background-color: #E4E4E7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: #1A1A1AB2;
            height: 24px;
            width: 23px;
          }
        }

        .dealHistory {
          width: 80px;
          height: 37px;
          box-shadow: 0px 0px 0px 5px #ffffff, 0px 0px 10px 6px #8bb7ff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 3px solid #8bb7ff;
          color: var(--base-700-light-tertiary, rgba(26, 26, 26, 0.7));
          text-align: center;

          font-size: 18px;
          font-family: Pretendard;
          font-weight: 600;
          line-height: 30px;
        }
        .detailItemWrap {
          display: flex;
          flex-direction: row;
          gap: 6px;
          border-bottom: 2px solid #2463eb;

          .itemInfo {
            color: var(--base-900-light, #1a1a1a);
            text-align: center;
            font-size: 18px;
            font-family: Pretendard;
            font-weight: 600;
            line-height: 30px;
          }
          .itemNumber {
            width: 32px;
            height: 28px;
            background-color: #2463eb;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
