@import "../../style/mixin.scss";

.cardWrap {
    background-color: #ffffff;
    width: 359px;
    height: 314px;
    border-radius: 10px;

    position: relative;

    .card {
        padding-top: 13px;

        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-left: 32px;
        padding-right: 28px;

        .headerIconWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .round {
                width: 74px;
                height: 74px;

                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 100%;
                background-color: #F2F2F2;;
                margin-left: auto;
            }
        }

        .header1 {
            font-weight: 600;
            font-size: 28px;
            margin-top: 15px;

            &.blue {
                color: #47cef9;
                font-family: "poppinsSemibold";
            }

            &.green {
                color: #afc81c;
                font-family: "poppinsSemibold";
            }

            &.purple {
                color: #8d0fda;
                font-family: "poppinsSemibold";
            }
        }

        .header2 {
            font-weight: 600;
            font-size: 22px;
            font-family: "poppinsSemibold";
            &.ko {
                @include font("koNotoM")
            }
        }

        .descWrap {
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            padding-top: 9px;
            font-family: "poppinsSemibold";
            &.ko {
                @include font("koNotoM")
            }
            
        }
    }
}

.mobileCardWrap {
    background-color: #ffffff;
    width: 287px;
    height: 178px;
    border-radius: 10px;

    position: relative;

    .mobileCard {
        padding-top: 10px;

        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 32px;
        padding-right: 28px;

        .mobileHeaderIconWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .mobileRound {
                width: 26px;
                height: 26px;

                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 100%;
                background-color: #F2F2F2;;
                margin-left: auto;
            }
        }

        .mobileHeader1 {
            font-weight: 600;
            font-size: 14px;
            padding-top: 5px;

            &.blue {
                color: #47cef9;
                @include font("smb");
            }

            &.green {
                color: #afc81c;
                @include font("smb");
            }

            &.purple {
                color: #8d0fda;
                @include font("smb");
            }
        }

        .mobileHeader2 {
            font-weight: 600;
            font-size: 16px;
            @include font("smb");
            &.ko {
                @include font("koNotoM")
            }
        }

        .mobileDescWrap {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            padding-bottom: 20px;
            @include font("mdm");
            &.ko {
                @include font("koNotoM")
            }
            
        }
    }
}
