@import "../../../style/mixin.scss";
.processWrap {
    width: 100%;
    background-color:
        #030523;
    min-height: 982px;
    .processesTopWrap{
        height: 634px;
    }

    .headerTextWrap {
        text-align: center;
        padding-top: 95px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .processHeader1{
            @include large-text;
            @include orange;
        }

        .processHeader2{
            color: #ffffff;
            @include super-text;
        }
        .processHeader3{
            padding-top: 5px;
            @include medium-text;
            @include orange;
        }
        .processHeader3Ko{
            padding-top: 5px;
            font-weight: 500;
            font-size: 15px;
            @include font("koNotoM");
            @include orange;
        }
    }

    .bottomBackground {
        width: 100%;
        height: 348px;
        background-color: #030523;
    }

    .progressbarContianer {
        position: relative;
        padding-bottom: 30px;
        min-height: 611px;
        width: 100%;
        max-width: 1260px;
        top: 37px;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        @media screen and (max-width: "990px") {
            :nth-child(3) {
                justify-content: flex-start;
            }
        }

        .progressWrap1 {
            display: flex;
            flex-direction: column;
            gap: 36px;
            padding-top: 50px;
            .progress1 {
                width: 325px;
                border-bottom: 4px solid rgba(74, 178, 237, 0.2);
                padding-bottom: 10px;
                height: 115px;
                @media screen and (max-width: "1010px") {
                    border-bottom: none;
                }
            }
        }
        .progressWrap2 {
            display: flex;
            flex-direction: column;
            padding-top: 50px;
            gap: 36px;
            .progress2 {
                width: 325px;
                border-bottom: 4px solid rgba(74, 178, 237, 0.5);
                padding-bottom: 10px;
                height: 115px;
            }
            .progress3 {
                width: 325px;
                border-bottom: 4px solid #4ab2ed;
                height: 115px;
                padding-bottom: 10px;
            }
            @media screen and (max-width: "1010px") {
                .progress2 {
                    border-bottom: none
                }
                .progress3 {
                    border-bottom: none;
                }
            }
        }
        
        .endCircle {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 20px solid #4ab2ed;
            border-bottom: 10px solid transparent;
            margin-top: 154px;
        }
        @media screen and (max-width: "1010px") {
            .endCircle {
                display: none;
            }
        }
    }
}
