@import "../../style/mixin.scss";

.serviceWrap {
    height: 782px;
    width: 100%;

    background-image: url(../../../public/image/nftbackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #030523;
    background-position: center;

    @media screen and (max-width: "650px") {
        background-image: none;
        height: 754px;
    }
}

.serviceBendWrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 120px;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;

    justify-content: center;
    align-items: center;
    gap: 51px;

    .serviceBendText {
        font-family: "poppinsSemibold";
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;

        color: #a59a97;
        @media screen and (max-width: "650px") {
            display: none;
        }
    }
    .serviceBendIcon {
        .companyIcon {
            display: flex;
            flex-direction: row;
            gap: 60px;
            align-items: center;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: 1275px) {
            .companyIcon {
                gap: 20px;
                flex-wrap: wrap;
            }
        }
        .mobileCompanyIcon{
            width: 100%;
            margin: 0 auto;
        }
        @media only screen and (max-width: 1027px){
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
