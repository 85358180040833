@import "../../../style/mixin";

.headerWrap {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #030523;
  height: 80px;

  .headerContainer {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;

    height: 80px;
    display: flex;
    gap: 100px;
    align-items: center;
    @media screen and (max-width: 950px) {
      justify-content: center;
      gap: 35px;
    }

    justify-content: space-between;
    .headerItem {

      .headerLogo {
        cursor: pointer;
        @media screen and (max-width: 1260px) {
          padding-left: 150px;
        }
        @media screen and (max-width: 950px) {
          padding-left: 0px;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 72px;
      .headerTextWrap {
        display: flex;
        flex-direction: row;
        gap: 58px;
        @media screen and (max-width: 950px) {
          gap: 40px;
          font-size: 12px;
        }

        .headerText {
          font-size: 15px;
          line-height: 22.5px;
          font-family: "poppinsLight";
          font-weight: 500;
          color: #fffcfc;
          opacity: 50%;

          &:hover {
            opacity: 100%;
            cursor: pointer;
          }
        }
      }
    }

    .headerLoginWrap {
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: 950px) {
        padding-right: 0px;
      }

      .loginText {
        font-size: 15px;
        line-height: 22.5px;
        font-family: "poppinsLight";
        color: #ffffff;
        font-weight: 600;
        white-space: nowrap;
      }

      .signUpbtn {
        display: flex;
        min-width: 137px;
        font-family: "poppinsLight";
        justify-self: right;
      }
    }
  }
}


.headerLanguageChange {
  cursor: pointer;
  position: relative;

  .languageChangeWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .languageTranslate {
      color: #ffffff;
      @include font("nanumAcEB");
      cursor: pointer;
    }
  }

  .languageMenu {
    width: 213px;
    height: 100px;

    z-index: 3;
    height: 80px;
    width: 180px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    position: absolute;
    right: 20px;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);

    .isSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 16px;
    }
    .isNotSelected {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      font-size: 16px;
      background-color: rgb(214 212 222 / 95%);
    }

    .isKorSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      border-bottom-left-radius: 5px;
      font-size: 16px;
      border-bottom-right-radius: 5px;
    }
    .isKorNotSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      font-size: 16px;
      background-color: rgb(214 212 222 / 95%);
    }
  }
}