@import "../../../style/mixin.scss";

  .finger{
    position: absolute;
    transform: rotate(90deg);
    right: 200px;
    bottom: -50px;
  }
.finger2{
  position: absolute;
  right: 300px;
  top: 40px;
}
.finger5{
  position: absolute;
  left: 120px;
  top: 250px;
}
.finger6{
  position: absolute;
  left: 160px;
  top: 350px;
}
.finger3{
  position: absolute;
  right: 140px;
  top: 25px;
}
.finger4{
  position: absolute;
  right: 60px;
  top: 50px;
}

.deployBtnWrap{
  position: relative;
}
.contractComplete{
  width: 384px;
  height: 174px;
  background-color: #ffffff;
  border: 1px solid rgba(26, 26, 26, 0.3);
  border-radius: 8px;

  .contractCompleteWrap{
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .contractCompleteTitle{
      @include font("preBold");
      font-size: 24px;
      color: #1A1A1A;
    }

    .contractCompleteInfo{
      display: flex;
      flex-direction: column;
      gap: 14px;
      .contractCompleteDesc{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        @include font("preM");
        color: rgba(26, 26, 26, 0.50);
      }
    }
  }
}


.mintingModalContainer{
  width: 384px;
  height: 370px;
  background-color: #ffffff;
  border: 1px solid rgba(26, 26, 26, 0.3);
  border-radius: 8px;

  .mintingModalWrap{
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    .mintingBtn{
      width: 352px;
      height: 44px;
      border: 1px solid var(--primary-blue-700-light, #1D4FD7);
      background: #2463EB;
      color: #ffffff;
      border-radius: 8px;
      font-size:16px;
      @include font("preSB");
      box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
      cursor: pointer;
    }

    .mintingModalContent{
      display: flex;
      flex-direction: column;
      gap: 24px;

      .mintingModalHeader{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .mintingModalTitle{
          @include font("preBold");
          font-size: 24px;
          color: #1A1A1A;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .closeBtn{
            cursor: pointer;
          }
        }

        .mintingModalDesc{
          @include font("preRegular");
          font-size:16px;
          color: rgba(26, 26, 26, 0.70);
        }
      }

      .mintingModalDetails{
        display: flex;
        flex-direction: column;


        .mintingModalNftList{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid rgba(26, 26, 26, 0.3);
          padding-bottom: 5px;
          .mintingModalNftListTitle{
            @include font("preM");
          color: rgba(26, 26, 26, 0.50);
          }
          .mintingModalNftListValue{
            @include font("preSB");
            color: rgba(26, 26, 26, 0.70);
          }
        }

        .mintingModalNftRange{
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          justify-content: space-between;
          padding-bottom: 5px;
          .mintingModalRangeTitle{
            @include font("preM");
            color: rgba(26, 26, 26, 0.50);
            font-size:16px;
          }
          .mintingModalRangeValue{
            @include font("preSB");
            color: rgba(26, 26, 26, 0.70);
            font-size:16px;
          }
        }

        .mintingModalTokenInfo{
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          justify-content: space-between;
          .mintingModalTokenHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 150px;
            .dot{
              background-color: rgba(26, 26, 26, 0.50);
              border-radius: 100%;
              width: 4px;
              height: 4px;
            }
          }
          .mintingModalTokenInfoTitle{
            display: flex;
            flex-direction: row;
            margin-left: 10px;
            list-style-type: number;
            @include font("preM");
            color: rgba(26, 26, 26, 0.50);
            font-size:16px;
          }
          .mintingModalTokenInfoDetails{
            white-space: normal;
            font-size: 14px;
            text-align: right;
            @include font("preSB");
            color: rgba(26, 26, 26, 0.70);
          }
        }
      }
    }
  }
}

  .deployContract{
    width: 384px;
    height: 202px;
    background-color: #ffffff;
    border: 1px solid rgba(26, 26, 26, 0.3);
    border-radius: 8px;

    .deployContractWrap{
      padding: 16px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      .deployContractTitle{
        @include font("preBold");
        font-size: 24px;
        color:#000000;
      }
      .deployStatusWrap{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .deployStatus{
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          height: 44px;
          color: rgba(26, 26, 26, 0.7);
          @include font("preBold");
        }
        .lottieWrap{
          width: 44px;
          height: 44px;
        }
        .iconWrap{
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
.contractModal {
  width: 384px;
  height: 381px;
  background-color: #ffffff;
  border: 1px solid rgba(26, 26, 26, 0.3);
  border-radius: 8px;

  .contractModalWrap {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .deployBtn{
      width: 352px;
      height: 44px;
      background-color: #2463EB;
      color: #ffffff;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
      @include font("preSB");
      cursor: pointer;
    }

    .contractModalHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .contractModalHeaderTitle {
        @include font("preBold");
        font-size: 24px;
      }
      .exitContractModal {
        cursor: pointer;
      }
    }

    .contractModalContentStep1 {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .contractModalInfoWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid rgba(26, 26, 26, 0.3);

        .contractModalInfoDetails {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 8px;

          .contractModalInfoDetailsTitle {
            @include font("preM");
            color: rgba(26, 26, 26, 0.5);
            font-size: 16px;
          }

          .contractModalInfoDetailsValue{
            color: rgba(26, 26, 26, 0.7);
            @include font("preSB");
            font-size: 16px;
          }
        }
      }
      .contractModalInfoWrap2 {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .contractModalInfoDetails {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .contractModalInfoDetailsTitle {
            @include font("preM");
            color: rgba(26, 26, 26, 0.5);
            font-size: 16px;
          }

          .contractModalInfoDetailsValue{
            color: rgba(26, 26, 26, 0.7);
            @include font("preSB");
            font-size: 16px;
          }
        }
      }
    }
  }
}

.projectManageArticle {
  background-color: #ffffff;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  width: 100%;
  max-width: 1440px;
  margin: 32px auto;
  border-radius: 8px;

  .projectManageWrap {
    padding: 64px 112px 0 112px;
    display: flex;
    flex-direction: column;
    gap: 46px;
    @media screen and (max-height: 900px) {
      padding: 20px 112px 0 16px;
      gap: 24px;
    }

    .projectManageHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .projectManageTitle {
        @include font("preBold");
        font-size: 30px;
        color: #000000;
      }

      .projectManageBtnWrap {
        display: flex;
        gap: 16px;
        flex-display: row;
        position: relative;

        .mintingBtn {
          display: flex;
          cursor: pointer;
          flex-direction: row;
          gap: 18px;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          background-color: #2463eb;
          border: none;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
          box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
        }
        .mintingBtnNot {
          display: flex;
          flex-direction: row;
          gap: 18px;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          color: rgba(26, 26, 26, 0.5);
          background-color: rgba(26, 26, 26, 0.2);
          border: none;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
        }
        .mintingBtnComplete {
          display: flex;
          flex-direction: row;
          gap: 18px;
          justify-content: center;
          cursor: pointer;
          align-items: center;
          align-items: center;
          color: #2463eb;
          background-color: #ffffff;
          border: 2px solid #2463eb;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
        }

        .contractBtn {
          display: flex;
          flex-direction: row;
          gap: 18px;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          background-color: #2463eb;
          border: none;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
          border: 2px solid #3479e9;
          box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
          cursor: pointer;
        }
        .contractBtnReady{
          display: flex;
          flex-direction: row;
          gap: 18px;
          justify-content: center;
          align-items: center;
          color: #2463EB;
          background-color: #ffffff;
          cursor: pointer;
          border: none;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
          border: 2px solid #3479e9;
        }
        .contractBtnNot {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          gap: 18px;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          background-color: #2463eb;
          border: none;
          border-radius: 8px;
          width: 225px;
          height: 51px;
          @include font("preSB");
        }
      }
    }

    .projectManageContent {
      display: flex;
      flex-direction: row;
      gap: 32px;

      .projectManageContentItems {
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media screen and (max-width: 1650px) {
          width: 80%;
        }

        .projectManageContentItemTitleWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .projectManageContentItemTitle {
            @include font("preBold");
            font-size: 30px;
            color: #000000;
          }
        }

        .projectManageContentMain {
          display: flex;
          flex-direction: column;
          gap: 25px;
          position: relative;

          .projectManageContentItemDesc {
            @include font("preM");
            font-size: 16px;
            color: #000000;
            line-height: 24px;
          }

          .moveToProject {
            width: 276px;
            height: 51px;
            display: flex;
            flex-direction: row;
            gap:10px;
            color: #ffffff;
            justify-content: center;
            align-items: center;
            background-color: #2463eb;
            border: none;
            border-radius: 8px;
            @include font("preSB");
            cursor: pointer;
            box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
          }

          .projectManageContentMainCompanyInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 14px;

            .projectManageContentMainCompanyInfoTitle {
              width: 154px;
              border-right: 1px solid rgba(26, 26, 26, 0.3);
              @include font("preSB");
              color: #000000;
            }

            .projectManageContentMainCompanyInfoValue {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 14px;
            }
          }
        }
      }
      .projectManageContentDate {
        @include font("preSB");
        font-size: 18px;
        height: 70px;
        display: flex;
        flex-dierection: row;
        gap: 14px;
        align-items: center;
        border-bottom: 1px solid rgba(26, 26, 26, 0.1);
        border-top: 1px solid rgba(26, 26, 26, 0.1);
      }

      .projectManageContentChainInfo {
        display: flex;
        flex-direction: row;
        gap: 40px;

        .chainInfoItemWrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          color: #71717a;
          @include font("preSB");

          .chainInfoValue {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
            font-size: 20px;
            @include font("preSB");
            color: #111111;
          }
        }

        .tokenInfo {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          color: #71717a;
          .tokenInfoTitle {
            font-size: 14px;
            color: #71717a;
            @include font("preSB");
          }
          .tokenInfoValue {
            font-size: 20px;
            @include font("preSB");
            color: #111111;
          }
        }

        .mintingInfo {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          color: #71717a;
          .mintingInfoTitle {
            font-size: 14px;
            color: #71717a;
            @include font("preSB");
          }
          .mintingInfoValue {
            font-size: 20px;
            @include font("preSB");
            color: #111111;
          }
        }

        .contractInfo {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          color: #71717a;
          fon-size: 14px;
          color: #71717a;
          @include font("preSB");

          .ContractInfoValue {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
            font-size: 20px;
            @include font("preSB");
            color: #ef4343;
            .deployedContract {
              color: #1A1A1A;
            }
          }
        }
      }
      .projectManageContentImage img {
        border: 3px solid #ffffff;
        border-radius: 20px;
        width: 180px;
        height: 180px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.35) 0 4px 15px;
        @media screen and (max-width: 1500px) {
          width: 90px;
          height: 90px;
        }
      }
    }
    .projectManageBottom {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .projectManageBottomItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .projectManageBottomItemTitle {
          color: #000000;
          @include font("preSB");
          font-size: 20px;
        }

        .nftBtn {
          width: 162px;
          height: 44px;
          display: flex;
          gap: 12px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #ffffff;
          border: 2px solid #3479e9;
          box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 10px 8px #3b82f6;
          @include font("preSB");
          cursor: pointer;
        }

        .nftBtnNot {
          width: 162px;
          height: 44px;
          display: flex;
          gap: 12px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #ffffff;
          border: 1px solid rgba(26, 26, 26, 0.7);
          @include font("preSB");
          cursor: pointer;
        }
      }
    }
  }
}
