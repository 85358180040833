@import "../../style/mixin.scss";

.leaderInfoContainer{
  display: flex;
  flex-direction: row;
  gap: 45px;
  padding-top: 115px;
  @media screen and (max-width: 1100px) {
    padding-top: 0px;
    width: 100%;
    max-width: 300px;
  }

  .profileDescWrap{
    display: flex;
    flex-direction: column;
    gap: 27px;
    @media screen and (max-width: 1100px) {
      gap: 19px;
    }
    .profileName{
      @include large-text;
    }
    @media screen and (max-width: 1100px) {
      .profileName{
        font-size: 16px;
      }
    }
    
    .infoWrap{
      display: flex;
      flex-direction: column;
      gap: 25px;
      @media screen and (max-width: 1100px) {
        gap: 7px;
      }

      .info1{
        @include font("smb");
        font-size: 20px;
        color: #A7A7A7;
      }

      .info2{
        @include font("smb");
        font-size: 20px;
        @include orange;
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;
      }

      .info3{
        @include font("smb");
        font-size: 20px;
      }

      @media screen and (max-width: 1100px) {
        .info1{
          @include font("smb");
          font-size: 12px;
          color: #A7A7A7;
        }
  
        .info2{
          @include font("smb");
          font-size: 12px;
          @include orange;
        }
  
        .info3{
          @include font("smb");
          font-size: 12px;
        }
      }
    }
  }
}  